@import '../settings/colors';
@import '../settings/fonts';

.progression {
  position: absolute; top: 10%; left: 95px; bottom: 10%; width: 5px; opacity: 0; transform: translate3d(0, 50px, 0);
  transition: all 250ms linear;

  &--active { opacity: 1; transform: translate3d(0, 0, 0); }
  .progression-bar { height: 100%; background-color: $light-brown; }
  .progression-dot {
    position: absolute; left: 0;
    transform: translate3d(-6.5px, -50%, 0);
    color: $light-brown;

    &--25 { top: 25%; }
    &--50 { top: 50%; }
    &--75 { top: 75%; }

    &--passed {
      color: $red;
    }
  }
  .progression-bar-status {
    position: relative; height: 0%; background-color: $red;
    transition: height 200ms linear;
  }
  .progression-bar-label {
    position: absolute; top: 0; left: 0; margin: 0;
    font-size: 2em; font-family: $roboto; font-weight: $fw-semibold; line-height: 1; color: $red; white-space: nowrap;
    transition: top 200ms linear;
    transform: translate3d(-6.5px, -50%, 0);

    &:before {
      margin-right: 15px;
      font-family: $fontAwesome;
    }
  }
}
