////
/// Reset styles
/// Ease cross browser styling
///
/// @group Generic
///
////

* { box-sizing: border-box; }

/// Reset font-size for easier use : 1.6em = 16px
html { font-size: 62.5%; line-height: 1.3; }
body {
  margin: 0; padding: 0;
  font-size: 1em;
  /// Improve font rendering
  -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased;
}

/// Reset nested font-size
blockquote p,td p,th p,li li,li p,li h1,li h2,li h3,li h4,li h5,li h6,li label,li select, li input, li textarea, li button { font-size: 100%; }


// Links & buttons
button { display: inline; margin: 0; border: none; padding: 0; outline: none; cursor: pointer; }
a img { border: none; }

// Images
img { max-width: 100%; height: auto;}

// Separator
hr { border: none; height: 1px; background-color: $black; }


// Forms
input, select, textarea { font-family: $font; }
fieldset { margin: 0; border: none; padding: 0; }
select { width: auto; cursor: pointer; }

input, select, textarea, label { vertical-align: middle; }
