////
/// Print
///
/// @group Trumps
///
////

// Print version
@media print {
  .hidden-print,
  [id="header"],
  [id="footer"] { display: none !important; }
}
