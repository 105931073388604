////
/// Colors
///
/// @group Settings
///
////

/// Grey scale

$white: #fff;
$black: #303044;

//// Theme
$grey: #494949;
$dark-grey: #2f2f2f;
$middle-grey: #9d9d9d;
$light-grey: #bababa;
$lightest-grey: #e9e9e9;
$white-grey: #f2f2f2;

$primary: #ee4322;
$red: #c62828;
$light-red: lighten($red, 10%);
$middle-red: darken($red, 20%);
$dark-red: darken($red, 40%);

$brown: #5d4037;
$light-brown: lighten($brown, 55%);
$dark-brown: #4e342e;
$darkest-brown: #3e2723;

// Transparent grey scale
$green: #5cb85c;

/// Functionnal colors
$color-main: $red;
$color-text: $black;
$color-link: $color-main;
$color-error: $red;
$color-valid: $green;
