@import '../settings/colors';
@import '../settings/fonts';

.card.summary {
  margin: 0 15%;
  height: 100vh;

  .summary-thanks {
    position: relative;
    margin-top: -10px;
    padding: 15px;
    background-color: $primary;
    z-index: 11001;
    color: $white;

    .small{
      font-size: 1.1em;
    }

    .congratulations > div {
      height: auto !important;
    }
  }
  .question-chart {
    position: relative;

    > div {
      position: absolute !important;
      left: 50%;
      margin: 0;
      transform: rotate(-90deg) translate3d(50%, -50%, 0);
      overflow: visible;
    }

    .question-chart-value {
      position: absolute;
      left: 50%;
      margin: 0;
      transform: translate3d(-50%, -50%, 0);
      line-height: 1;
      font-size: 4em;
      &.multiple {
        font-size: 2.25em;
      }
      .count {
        font-size: 0.5em;
        text-align: center;
      }
    }
  }
  .question-chart-evolution {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h1 {
    height: auto !important;
    line-height: 1.6em !important;
    white-space: normal !important;
  }
  pre {
    padding: 0.5em 1em;
    height: auto !important;
    font-size: 1.2em;
  }
  .list-item {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: rgba($light-brown, 0.8);
      opacity: 0;
      cursor: pointer;
      transition: opacity 250ms linear;

      > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    p {
      height: auto !important;
      overflow: visible !important;
      white-space: normal;
    }
    .right {
      color: $red;
      font-weight: $fw-semibold;
      &.new-line {
        white-space: pre-line;
      }
    }
    .wrong {
      color: $red !important;
      text-decoration: line-through;
    }

    .answer-block {
      margin: 4px 0px 0px;
      text-overflow: ellipsis;
      font-size: 14px;

      p,
      div {
        line-height: 17px;
        color: rgba(0, 0, 0, 0.54);
        text-overflow: ellipsis;
        margin: 0;
        font-size: 1em;
      }
    }
  }
  .list-item:hover .overlay {
    opacity: 1;
  }
  &.card--readonly {
    margin: 0 auto;
    width: 90%;
    max-width: 900px;
    .list-item:hover > div > span {
      background: none !important;
    }
  }
}
