////
/// Alignement
///
/// @group Objects
///
////
.align-left { text-align: left; }
.align-center { text-align: center; }
.align-right { text-align: right; }
.align-justify {
  text-align: justify;
  &.align-center { text-align-last: center; }
}
