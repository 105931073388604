////
/// Fonts
///
/// @group Settings
///
////

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

/// Font fomily
$roboto: 'Roboto', sans-serif;
$fontAwesome: 'FontAwesome';
$font: $roboto;

/// Fonts weight
/// They must reflect avalaible weights
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 500;
$fw-bold: 700;
