////
/// Forms
///
/// @group Elements
///
////

$type-list: text tel email url search date time datetime month week number;
@each $type in $type-list {
  [type="#{$type}"] {
    border: 1px solid $black; border-radius: 3px; background-color: $white;

    &:focus { outline: none; }
  }
}
select,
textarea {
  border: 1px solid $black; border-radius: 3px; background-color: $white;

  &:focus { outline: none; }
}
