////
/// Navigation
///
/// @group Objects
///
////
.list-inline {
  display: block; margin: 0; padding: 0;

  > * { display: inline-block; margin: 5px; padding: 0; }
}
