////
/// Form elements basic styling
///
/// @group Objects
///
////

.form-error { outline-color: $color-error; }
.form-valid { outline-color: $color-valid; }
[contenteditable]:focus {  outline: 0px solid transparent;  }
