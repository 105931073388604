////
/// Links & buttons
///
/// @group Elements
///
////

button,
a {
  text-decoration: none; color: $color-link;

  &:hover { text-decoration: underline; }
}
button {
  background-color: transparent;
  color: $color-link;
}
