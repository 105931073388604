////
/// Headings
///
/// @group Elements
///
////
h1 {
  margin: 25px 0;
  font-size: 3.5em;
}
h2 {
  margin: 25px 0;
  font-size: 2.4em;
}
h3 {
  margin: 25px 0;
  font-size: 2em;
}
h4 {
  margin: 20px 0;
  font-size: 1.8em;
}
h5 {
  margin: 20px 0;
  font-size: 1.6em;
}
h6 {
  margin: 15px 0;
  font-size: 1.4em;
}
h1, h2, h3, h4, h5, h6 { line-height: 1; font-weight: $fw-bold; }
