////
/// Page
///
/// @group Objects
///
////

.container {
  margin: auto; padding: 0 $page-padding; width: $max-width; max-width: 100%;
}
