////
/// Headings
///
/// @group Elements
///
////

/// Contents
li { margin: 5px 0; }
p { margin: 15px 0; }
p, li, td, th, textarea, caption, pre, blockquote, label, input, select, textarea { font-size: 1.4em; line-height: 1.6; }
td p, td .bodytext { margin: 0; }
strong, optgroup { font-weight: $fw-bold; }
