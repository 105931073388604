@import "../settings/colors";

.card.question {
  > div { padding-top: 0 !important; }
  .question-content { max-height: 600px; overflow-y: auto; }
  h1 {
    height: auto !important;
    line-height: 1.6em !important; white-space: normal !important;
  }
  .letter {
    padding: 3px; border: 1px solid $light-grey; border-radius: 2px;
    text-transform: uppercase; text-align: center;
  }
  pre {
    padding: .5em 1em;
    font-size: 1.2em;

    code { display: block; width: 100%; min-height: 80px; }
  }
  .code-editor { min-height: 100px; cursor: pointer; }
  .answer--active {
    background: $lightest-grey !important;
  }
  .help-multiple {
    float: left;
    color: $grey;
  }
}

.card.card-single .letter { border-radius: 50%; padding: 6px 10px; width: 30px !important; height: 30px !important; }
